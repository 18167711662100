
.explore {
    position: relative;
    width: 100vw;
    &_menu {
        position: absolute;
        background-color: #2E1A47;
        top: 0;
        left: 0;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .logo {
            position: relative;
            margin: 2em;
            min-width: 30em;
            width: 40vw;
            cursor: pointer;
            img {
                width: 100%;
            }
        }
        .fullscreen {
            color: rgb(194, 194, 194);
            font-size: 3em;
            position: absolute;
            top: 0.5em;
            right: 0.5em;
            cursor: pointer;
        }
    }
    &_content {
        width: 100%;
        height: 100%;
        background-image: url(../images/purple_graphic_background.svg);
        align-items: center;
        justify-content: center;
        flex-direction: column;
        object-fit: cover;
        &_swiper{
            width: 100%;
            height: 100%;
            &_slide{
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .first_marker{
                    position: absolute;
                    transform: translate(-50%, -50%);
                    width: 150px;
                    height: 50px;
                    background-color: #dadada00;
                    cursor: pointer;
                }
                .second_marker{
                    position: absolute;
                    transform: translate(-50%, -50%);
                    width: 150px;
                    height: 62px;
                    background-color: #dadada00;
                    cursor: pointer;
                }
                img{
                    height: 100%;
                }
            }
        }
    }
    &_skip{
        position: fixed;
        bottom: 3em;
        right: 2em;
        z-index: 4;
        color: #BDB5AE !important;
    }
}