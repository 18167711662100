.select_floor_plans_container{
    width: 100vw;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
}

.select_floor_plans {
    .plans-header {
        display: flex;
        justify-content: space-between;
        background: #2E1A47;
        padding: 2vw;
        .icon-wrapper {
            width: 5vw;
            min-width: 3em;
            img {
                width: 100%;
            }
        }
        .title-wrapper {
            margin-top: auto;
            font-size: 4em;
            color: #FFFFFF;
            @include bp("tablet"){
                font-size: 6vw;
            }
        }
    }
    .plans-container {
        background: #C4C4C4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 1em;
        gap: 1.5em;
        .plan-item {
            max-width: 45%;
            min-width: 530px;
            .floor-area {
                width: 100%;
                background: #ffffff;
                cursor: pointer;
                .floor-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1em;
                    img {
                        width: 100%;
                    }
                }
                .floor-bottom {
                    background: #E2E0DF;
                    border-top: 1px solid #2E1A47;
                    padding: 20px 30px;
                    position: relative;
                    .info-container {
                        div {
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.06em;
                        }
                    }
                    .bottom-title {
                        font-size: 1.5vw;
                        line-height: 34px;
                        color: #2E1A47;
                        right: 20px;
                        bottom: 20px;
                        letter-spacing: 0.06em;
                    }
                }
            }
        }
    }
    .home-container {
        display: flex;
        padding: 1em;
        background: #F1F1F1;
        flex-wrap: wrap;
        .gallery-image{
            flex: 1;
            min-width: 300px;
            img {
                width: 100%;
            }
        }
        .text-container {
            min-width: 400px;
            width: 40vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 2em;
            .call {
                font-size: 2vw;
                line-height: 150%;
                text-align: right;
                color: #0B253C;
            }
            .home {
                font-size: 3em;
                text-align: left;
                color: #2E1A47;
                line-height: 150%;
            }
            .text-content {
                width: 80%;
                float: right;
                line-height: 150%;
                letter-spacing: 0.1em;
                margin-top: 1.5em;
            }
        }
    }
    .gallery-container {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1em;
        padding: 1em;
        .gallery-wrapper {
            min-width: 380px;
            width: 45%;
            overflow: hidden;
            div{
                height: 400px;
                background-size: cover !important;
                background-repeat: no-repeat !important;
                background-origin: border-box !important;
                background-position: center center !important;
            }
        }
    }
    .footer-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2em 3em;
        background: #C4C4C4;
        flex-wrap: wrap;
        gap: 2em;
        @include bp("tablet"){
            justify-content: center;
            padding: 1em 0 !important; 
        }
        .contact {
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.165em;
            color: #000000;
        }
        .address {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.165em;
            color: #000000;
        }
        img {
            width: 240px;
        }
    }
}