.team {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    .team-card {
        position: relative;
        width: 50%;
        height: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &.adellco {
            background: rgba(46, 26, 71, 0.7);
        }
        &.form {
            background: rgba(141, 141, 140, 0.5);
        }
        &.cabinet {
            background: rgba(46, 26, 71, 0.4);
        }
        &.corcoran {
            background: #E2E0DF;
        }
        &.active {
            background: #2E1A47;
        }
        .big-title {
            font-size: 3.8vw;
            line-height: 150%;
            color: #FFFFFF;
        }
        .small-title {
            font-size: 1.6vw;
            line-height: 110%;
            letter-spacing: 0.17em;
            color: #3B1A53;
        }
        .active-text {
            font-size: 1.2vw;
            line-height: 150%;
            letter-spacing: 0.005em;
            color: #E2E0DF;
            padding: 0 68px;
            text-align: center;
        }
    }
}