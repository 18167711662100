.residences {
    width: 100vw;
    .left-bar {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 100;
        width: 350px;
        background: #e2e0df;
        border: 1.94623px solid #2e1a47;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: calc(100% - 150px);
        @include bp("phone") {
            width: 100%;
            height: 100%;
        }
        .header-container {
            padding: 1em;
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            .back-button {
                width: 2em;
                height: 2em;
            }
            .header-title {
                font-size: 2em;
                line-height: 100%;
                letter-spacing: -0.015em;
                color: #2e1a47;
                margin-left: 1em;
            }
        }
        .floor-area {
            width: 100%;
            height: 100%;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 90%;
                @include bp("laptop") {
                    width: 60%;
                }
            }
        }
        .rooms-area {
            display: flex;
            align-items: center;
            width: 100%;
            .room {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border: 0.460854px solid #2e1a47;
                &:first-child {
                    border-right: none;
                }
                .room-title {
                    font-size: 18.0017px;
                    line-height: 22px;
                    letter-spacing: 0.075em;
                    color: #000000;
                }
                .room-count {
                    font-size: 25px;
                    line-height: 30px;
                    letter-spacing: -0.015em;
                    color: #2e1a47;
                }
            }
        }
        .interior,
        .view {
            font-size: 18.4342px;
            line-height: 22px;
            letter-spacing: -0.015em;
            color: #000000;
            padding: 20px;
            width: 100%;
            text-align: center;
            border-bottom: 0.460854px solid #2e1a47;
        }
        .action-container {
            padding: 1.3em;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .action {
                width: 5vw;
                max-width: 4em;
                min-width: 3em;
                cursor: pointer;
            }
        }
        .view-container {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .bottom-container {
                padding: 30px;
                width: 100%;
                .view-title {
                    font-size: 19.7621px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.19em;
                    color: #2e1a47;
                    margin-bottom: 30px;
                }
                .action-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
    .view-mode-button {
        position: fixed;
        bottom: 0.5vw;
        right: 0.5vw;
        z-index: 1;
        width: 8vw;
        min-width: 5em;
        height: 8vw;
        min-height: 5em;
        img {
            width: 100%;
        }
    }
    .apartment {
        width: 100%;
        height: 100%;
        background-color: antiquewhite;
    }
}

.floor-plan {
    z-index: 1002;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    top: 0;
    .close-btn {
        position: absolute;
        top: 1em;
        left: 1em;
        cursor: pointer;
    }
    .pdf-area {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        & .floorplan-page {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & canvas {
            height: calc(100vh - 104px) !important;
            @include bp("tablet") {
                width: 100vw !important;
                height: auto !important;
            }
        }
        & .pdf-controls {
            position: absolute;
            bottom: 2em;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .back-container {
        padding: 25px;
        position: relative;
        background: #e2e0df;
        .back {
            position: absolute;
            top: 40px;
            left: 35px;
            cursor: pointer;
        }
        .action-bar {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 58px;
                cursor: pointer;
                margin: 0 8px;
            }
        }
    }
}

.gallery {
    z-index: 1002;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    .gallery-container {
        position: relative;
        .gallery_content_swiper_slide {
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgb(240, 240, 240);
            img {
                height: 100vh;
            }
        }
        .back {
            position: absolute;
            bottom: 30px;
            left: 30px;
            z-index: 10;
            cursor: pointer;
        }
    }
}

.view-gallery {
    z-index: 1002;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    .gallery-container {
        width: calc(100vw - 400px);
        height: calc(100vh - 200px);
    }
    .gallery_content_swiper_slide {
        display: flex;
        align-items: center;
        justify-content: center;
        // background: rgb(240, 240, 240);
        img {
            height: 100%;
        }
    }
}

.residence_tour_page {
    z-index: 1002;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    .page-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            object-fit: cover;
            min-width: 100%;
            height: 100%;
        }
    }
    .back {
        position: absolute;
        bottom: 30px;
        left: 30px;
        cursor: pointer;
    }
    .media-content {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        .circle {
            border: 1.69559px solid #ffffff;
            width: 400px;
            height: 400px;
            border-radius: 50%;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .small-circle {
                border: 1.69559px solid #ffffff;
                border-radius: 50%;
                width: 388px;
                height: 388px;
                font-size: 55px;
                line-height: 66px;
                text-align: center;
                letter-spacing: 0.085em;
                color: #aaa2b4;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .residence_tour {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            .circle {
                .small-circle {
                    background: rgba(69, 69, 69, 0.5);
                    color: #ffffff;
                }
            }
        }
    }
}

.share {
    z-index: 1002;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    .share-container {
        width: 765px;
        background: #9c9c9c;
        .share-header {
            padding: 2em 3em;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            position: relative;
            .title {
                font-size: 5em;
                letter-spacing: -0.02em;
                color: #ffffff;
                margin-right: 1em;
            }
            .action {
                width: 3em;
                cursor: pointer;
            }
            .close-btn {
                position: absolute;
                font-size: 1em;
                top: 0;
                right: 0;
                cursor: pointer;
            }
        }
        .divider {
            width: 100%;
            height: 8px;
            background: #2e1a47;
        }
        .more-action-container {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                cursor: pointer;
            }
        }
        .more-container {
            display: flex;
            align-items: center;
            // justify-content: center;
            flex-direction: column;
            flex-wrap: wrap;
            background: #c4c4c4;
            padding: 10px 0;
            height: 260px;
            .floor-btn {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 1.5em;
                line-height: 33px;
                letter-spacing: -0.02em;
                cursor: pointer;
                margin: 5px 0;
                color: #2e1a47;
                &.active {
                    border: 1px solid #2e1a47;
                    color: #2e1a47;
                }
            }
        }
    }
}

.brochure {
    z-index: 1002;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    top: 0;
    .brochure-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    canvas {
        margin-top: 5vh;
        height: 80vh !important;
        @include bp("laptop"){
            margin-top: 5vh;
            height: 70vh !important;
        }
    }
    .page-navigation {
        position: fixed;
        bottom: 5vw;
        left: 50%;
        display: flex;
        align-items: center;
        cursor: pointer;
        transform: translate(-50%, 0);
    }
    .back {
        position: fixed;
        left: 5vw;
        bottom: 5vw;
        cursor: pointer;
        z-index: 1000;
    }
}
