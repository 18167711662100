/* @import url('https://fonts.googleapis.com/css?family=Azo+Sans'); */

@import url('https://fonts.googleapis.com/css?family=Noto+Sans');
@import url('https://fonts.googleapis.com/css?family=Lora');
@import url('https://fonts.googleapis.com/css?family=Helvetica');
@import url('https://fonts.googleapis.com/css?family=Teimer');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "English1766", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  position: relative;
}

@mixin bp($point) {
	// VIEWPORT SIZES
	$bp-television: "screen and (max-width: 1920px)"; // television & up
	$bp-laptop: "screen and (max-width: 1400px)";     // laptop & up
	$bp-tablet: "screen and (max-width: 1024px)";      // tablet vertically & up
	$bp-phone: "screen and (max-width: 576px)";       // phone vertically & up
	$bp-mini: "screen and (max-width: 376px)";       // mini vertically & up
	// MEDIA QUERIES
    @if $point == television {     // television
		@media #{$bp-television} { @content; }
	}
    @if $point == laptop {     // laptop
		@media #{$bp-laptop} { @content; }
	}
    @if $point == tablet {     // tablet
		@media #{$bp-tablet} { @content; }
	}
	@if $point == phone {     // phone
		@media #{$bp-phone} { @content; }
	}
	@if $point == mini {     // mini
		@media #{$bp-mini} { @content; }
	}
}

.annotation-2d {
  width: 4em;
  height: 4em;
  border-radius: 100px;
  background-color: #FF66C4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.annotation-2d>span {
  padding-left: 0.18em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  font-size: 2em;
  user-select: none;
}

.large {
  width: 3.5em;
  height: 3.5em;
}

.small {
  width: 3em;
  height: 3em;
}

.small>span {
  font-size: 1.7em;
}

:root {
  --swiper-theme-color: #2E1A47;
}

.swiper-button-next{
  // background-color: rgb(247, 247, 247);
  padding: 1em;
  border-radius: 5em;
}

.swiper-button-prev{
  // background-color: rgb(247, 247, 247);
  padding: 1em;
  border-radius: 5em;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: auto !important;
  height: 100% !important;
}

@import "./styles/app";