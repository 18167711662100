:root {
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
}

.animate__animated.animate__fadeInRight {
  --animate-duration: 1s;
}

.animate__animated.animate__fadeIn {
  --animate-duration: 2s;
}

.canvasContainer {
  width: 100%;
  height: 100vh;
  margin: unset;
  padding: unset;
  position: absolute;
  top: 0;
  left: 0;
}

.canvasContainer canvas {
  width: 100% !important;
  height: 100% !important;
  outline: none;
}

.renderer2D {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: inherit;
  height: inherit;
}

.annotation {
  position: absolute;
  top: 0.3em;
  transform: translate(-50%, -50%);
  width: max-content;
  color: #85878A;
  font-size: 1.2em;
  font-weight: 400;
  text-transform: uppercase;
}

.active {
  color: aliceblue;
}

.title-wraper {
  position: absolute;
  top: 2em;
  left: 4em;
  z-index: 1;
  width: 40em;
}

.title-wraper .main {
  font-style: normal;
  font-weight: normal;
  font-size: 6vw;
  line-height: 100%;
  color: #BCB2A8;
}

.title-wraper .sub {
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 1em;
  letter-spacing: 0.135em;
  color: #867696;
  margin: 1em 0 0 1em;
}