.navigation {
    position: fixed;
    user-select: none;
    .menu-motion {
        width: 100vw;
        height: 100%;
    }
}

.menu-motion {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
}