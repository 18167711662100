.plan_finder_3d_canvas {
    background-color: #E2E0DF;
}

.plan_finder_3d_canvas_content {
    padding-top: 10px;
    /* transform: translate3d(50%, 50%, 0); */
    text-align: left;
    background-color: #2E1A47;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
}