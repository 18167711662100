.media {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    .media-image {
        width: 100vw;
        height: 100vh;
    }
    .media-content {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        .gallery-content {
            background: rgba(46, 26, 71, 0.5);
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .residence-content {
            background: rgba(38, 38, 38, 0.6);
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .circle {
            border: 1.69559px solid #FFFFFF;
            width: 400px;
            height: 400px;
            border-radius: 50%;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .small-circle {
                border: 1.69559px solid #FFFFFF;
                border-radius: 50%;
                width: 388px;
                height: 388px;
                font-size: 55px;
                line-height: 66px;
                text-align: center;
                letter-spacing: 0.085em;
                color: #AAA2B4;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .residence_tour {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            .circle {
                .small-circle {
                    background: rgba(69, 69, 69, 0.5);
                    color: #ffffff;
                }
            }
        }
    }
    .text {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: #FFFFFF;
        text-transform: uppercase;
    }
}