.gallery-grid{
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #262626;
    padding-top: 5em;
    & .grid-button{
        position: fixed;
        top: 1em;
        right: 1em;
        z-index: 1;
        color: aliceblue;
    }
}