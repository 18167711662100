.pdf_select_floor_plans {
    position: fixed;
    top: 5000;
    width: 1500px;
    height: 1924px;
    .plans-header {
        background: #2E1A47;
        .icon-wrapper {
            position: absolute;
            img {
                margin-top: 40px;
                margin-left: 56px;
                width: 97px;
            }
        }
        .title-wrapper {
            height: 180px;
            font-size: 60px;
            text-align: right;
            color: #FFFFFF;
            padding-top: 60px;
            padding-bottom: 10px;
            span {
                margin-right: 56px;
            }
        }
    }
    .plans-container {
        background: #C4C4C4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        height: 490px;
        overflow: hidden;
        .plan-item {
            width: 33.33333333%;
            padding: 15px;
            .floor-area {
                width: 100%;
                background: #ffffff;
                .floor-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1em;
                    height: 300px;
                    img {
                        width: 100%;
                        max-height: 95%;
                    }
                }
                .floor-bottom {
                    background: #E2E0DF;
                    border-top: 1px solid #2E1A47;
                    padding: 20px 30px;
                    position: relative;
                    .info-container {
                        div {
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.06em;
                        }
                    }
                    .bottom-title {
                        font-size: 20px;
                        line-height: 34px;
                        color: #2E1A47;
                        right: 20px;
                        bottom: 20px;
                        letter-spacing: 0.06em;
                    }
                }
            }
        }
    }
    .home-container {
        display: flex;
        padding: 30px 50px 50px;
        background: #F1F1F1;
        .gallery-image{
            width: 45%;
            img {
                width: 100%;
            }
        }
        .text-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 2em;
            .call {
                font-size: 2vw;
                line-height: 150%;
                text-align: right;
                color: #0B253C;
            }
            .home {
                font-size: 4em;
                text-align: left;
                color: #2E1A47;
                line-height: 150%;
            }
            .text-content {
                width: 100%;
                float: right;
                line-height: 150%;
                letter-spacing: 0.1em;
                margin-top: 1.5em;
                font-size: 1.3;
            }
        }
    }
    .gallery-container {
        background: #ffffff;
        display: flex;
        align-items: center;
        align-items: center;
        width: 100%;
        .gallery-wrapper {
            width: 50%;
            padding: 15px 20px;
            div{
                height: 400px;
                background-size: cover !important;
                background-repeat: no-repeat !important;
                background-origin: border-box !important;
                background-position: center center !important;
            }
        }
    }
    .footer-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        background: #C4C4C4;
        .contact {
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.165em;
            color: #000000;
        }
        .address {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.165em;
            color: #000000;
        }
        img {
            width: 240px;
        }
    }
}