.lobby {
    width: 100vw;
    div {
        width: 100%;
        height: 100%;
        .media-content {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            @include bp("phone") {
                & {
                    flex-direction: column;
                }
            }
            .gallery-content {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }
            .residence-content {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
            .circle {
                border: 1.69559px solid #ffffff;
                max-width: 380px;
                min-width: 190px;
                width: 30vw;
                max-height: 380px;
                min-height: 190px;
                height: 30vw;
                border-radius: 50%;
                padding: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .small-circle {
                    border: 1.69559px solid #ffffff;
                    border-radius: 50%;
                    max-width: 370px;
                    min-width: 180px;
                    width: 29vw;
                    max-height: 370px;
                    min-height: 180px;
                    height: 29vw;
                    font-size: 55px;
                    line-height: 66px;
                    text-align: center;
                    letter-spacing: 0.085em;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .text {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #ffffff;
            text-transform: uppercase;
        }
        .back {
            position: fixed;
            bottom: 1em;
            left: 1em;
        }
    }
}

.lobby-content {
    position: fixed;
    cursor: pointer;
    background: rgb(240, 240, 240);
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
